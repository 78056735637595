
import { apiGET, apiPOST } from '../../utils/apiHelper'



const getAllUsers = async (options) => {

  let payload = { options };

  const response = await apiPOST('/api/users/allUsers', payload);
  if (response.data.data) return response.data.data
  else return response?.message
}
const listAllDepositRequest = async (options) => {

  let payload = { options };

  const response = await apiPOST('/api/users/alldepositRequest', payload);
  if (response.data.data) return response.data.data
  else return response?.message
}
const listDeposit = async (options) => {

  let payload = { options };

  const response = await apiPOST('/api/users/deposit', payload);
  if (response.data.data) return response.data.data
  else return response?.message
}
const listThemes = async (options) => {


  const response = await apiGET('/api/users/themes');
  if (response.data.data) return response.data.data
  else return response?.message
}




const userService = {
    getAllUsers,
    listAllDepositRequest,
    listDeposit,
    listThemes
  }
  
  export default userService