import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import userService from './userService'

const initialState = {
  users: [],
  depositRequest:[],
  deposit:[],
  themes:[],
  isError: false,
  isSuccess: false,
  isLoading: false,
  themesLoading:false,
}


export const getAllUsers = createAsyncThunk(
  'users/allUsers',
  async (payload, thunkAPI) => {
    try {
      return await userService.getAllUsers(payload)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const listAllDepositRequest = createAsyncThunk(
  'users/alldepositRequest',
  async (payload, thunkAPI) => {
    try {
      return await userService.listAllDepositRequest(payload)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const listDeposit = createAsyncThunk(
  'users/deposit',
  async (payload, thunkAPI) => {
    try {
      return await userService.listDeposit(payload)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const listThemes = createAsyncThunk(
  'users/themes',
  async (thunkAPI) => {
    try {
      return await userService.listThemes()
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = action.payload?.data
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.users = null
      })


      .addCase(listAllDepositRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(listAllDepositRequest.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.depositRequest = action.payload?.data
      })
      .addCase(listAllDepositRequest.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.depositRequest = null
      })


      .addCase(listDeposit.pending, (state) => {
        state.isLoading = true
      })
      .addCase(listDeposit.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.deposit = action.payload?.data
      })
      .addCase(listDeposit.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.deposit = null
      })


      
      .addCase(listThemes.pending, (state) => {
        state.themesLoading = true
      })
      .addCase(listThemes.fulfilled, (state, action) => {
        state.themesLoading = false
        state.isSuccess = true
        state.themes = action.payload?.data
      })
      .addCase(listThemes.rejected, (state, action) => {
        state.themesLoading = false
        state.isError = true
        state.themes = null
      })
   

    
  },
})

export const { reset } = adminUserSlice.actions
export default adminUserSlice.reducer