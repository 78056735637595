import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savings:124738.32,
  current:224458.32,
  bitcoin:0.0023
};

export const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    updateSavings: (state, action) => {
      state.savings = action.payload.savings
    },
    updateCurrent: (state, action) => {
      state.current = action.payload.current
    },
    updateBitcoin: (state, action) => {
      state.bitcoin = action.payload.bitcoin
    },
  },
});

export const { updateSavings , updateBitcoin , updateCurrent } = balanceSlice.actions;
export default balanceSlice.reducer;
