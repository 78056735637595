import React, { lazy, Suspense } from 'react';
import { BrowserRouter , Switch} from "react-router-dom";
import AuthMiddleware from "../middleware/middleware";
import { ToastContainer } from "react-toastify";
import Navbar from "../navbar/Navbar";
import ScrollToTop from "./scrollToTop";
import {ReactComponent as LoaderLogo} from "../assets/loader.svg"
import "react-toastify/dist/ReactToastify.css";
import { Footer } from '../components/Footer';
import { useSelector } from 'react-redux';
const Login = lazy(()=>import("../pages/Login"))
const Trade = lazy(()=>import("../pages/trade"))
const UserList = lazy(()=>import("../admin/pages/UserList"))
const Transfer =  lazy(()=>import("../admin/pages/Transfer"))
const DepositRequest =  lazy(()=>import("../admin/pages/DepositRequestList"))
const Transaction =  lazy(()=>import("../admin/pages/Transaction"))
const Themes =  lazy(()=>import("../admin/pages/Themes"))
const Contact = lazy(()=>import("../pages/Contact"))
const EmailVerification = lazy(()=>import("../pages/pageEmailVerify"))  
const ForgotPassword = lazy(()=>import("../pages/ChangePassword"))


export const publicPages = [
  { path: "/", exact: true, component: Login, isPublic: false },
  { path: "/login", exact: true, component: Login, isPublic: true },
  { path: "/emailverify", exact: true, component: EmailVerification, isPublic: true },
  { path: "/contact", exact: true, component: Contact, isPublic: true },
  { path: "/forgotpassword", exact: true, component: ForgotPassword, isPublic: true },

]
export const userPages = [
  { path: "/contact", exact: true, component: Contact },
  { path: "/trade/:activeTab", component: Trade },
]
export const adminPages = [
  { path: "/", exact: true, component: UserList },
  { path: "/admin/user", exact: true, component: UserList },
  { path: "/admin/transfer", exact: true, component: Transfer },
  { path: "/admin/depositRequest", exact: true, component: DepositRequest },
  { path: "/admin/transactions", exact: true, component: Transaction },
  { path: "/admin/themes", exact: true, component: Themes },
]

const Routing = ({role}) => {

	const { themes } = useSelector((s) => s.adminUser);

  

  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
	  {role ==="Admin" ? null : <Navbar/>}
	  <ToastContainer/>
      <Suspense fallback={<div className='flex flex-1 h-[100vh] bg-black justify-center items-center'>
				 <LoaderLogo
				 className=' rotate linear infinite'/>
</div>}>
<Switch>
					{ role ==="User" && userPages.map((route, idx) => (
						<AuthMiddleware
							path={route.path}
							component={route.component}
							key={route.path + idx}
							isAuthProtected={true}
							exact={!!route.exact}
							isPublic={false}
							role={role}

						/>
					))}
					{ role ==="Admin" && adminPages.map((route, idx) => (
						<AuthMiddleware
							path={route.path}
							component={route.component}
							key={route.path + idx}
							isAuthProtected={true}
							exact={!!route.exact}
							isPublic={false}
							role={role}

						/>
					))}
					

					{publicPages.map((route, idx) => (
						<AuthMiddleware
							path={route.path}
							component={route.component}
							isPublic={route.isPublic}
							key={route.path + idx}
							isAuthProtected={!route.isPublic}
							exact={!!route.exact}
							role ={role}
						/>
					))}
				</Switch>
        {role ==="Admin" ?null : <div className={` ${themes?.title === "NBB Online" ? "bg-[#dfeaec]":"bg-[#ffff]"} `}><Footer/></div>}
        </Suspense>
        
    </BrowserRouter>
  );
};
export const ROUTELOADER = (WrappedComponent) => {
	function HOC(props) {
	  // const [role, setRole] = React.useState(null);
	  const mainRole = useSelector(
		(state) => state?.auth?.user?.role ?? ""
	  );
	  
	  return (
		<>
		  <WrappedComponent {...props} role={mainRole} />
		</>
	  );
	}
	return HOC;
  };
  
  export default ROUTELOADER(Routing);

