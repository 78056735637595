import { configureStore,combineReducers } from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";

import  authReducer from '../features/auth/authSlice';
import balanceReducer from "../features/auth/balanceSlice"
import adminUserReducer from "../features/user/userSlice"
const rootReducers ={
        auth: authReducer,
        balance:balanceReducer,
        adminUser:adminUserReducer
       
    }

const isDev = true

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ['auth'],
};

const rootReducer = combineReducers(rootReducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewareLogger = !!isDev ? logger : [];

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewareLogger),
});

export let persistor = persistStore(store);