import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localStorage
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const addUserData = createAsyncThunk('api/users/add', async (user, thunkAPI) => {
    try {
        return user
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const logout = createAsyncThunk('api/users/logout', async () => {
    await authService.Logout()
})







export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
        addSubAccount: (state, action) => {
            state.user ={
                ...state.user,
                subAccountId : action.payload.subAccountId,
                subAccountEmail: action.payload.subAccountEmail,
                subAccountAPIKey : action.payload.subAccountAPIKey,
                subAccountAPISecret : action.payload.subAccountAPISecret
                } ;
        },
   
    },
    extraReducers: (builder) => {
        builder
          
            .addCase(addUserData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
           
            
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
         
    },
})

export const { reset  , addSubAccount } = authSlice.actions
export default authSlice.reducer