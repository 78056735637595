import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Logo  from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";

const NAV = [
  {
    name: "Account",
    link: "/trade/savings",
  },
  {
    name: "Exchange",
    link: "/trade/exchange",
  },
  {
    name: "Deposit",
    link: "/trade/deposit",
  },
  {
    name: "Investments",
    link: "/trade/transactions",
  },
];

const Navbar = () => {
  const { user } = useSelector((s) => s.auth);
  const { themes } = useSelector((s) => s.adminUser);
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const [hamburger, setHamburger] = useState(false);
  const [role, setRole] = useState("");
  const handleClick = () => {
    setHamburger(!hamburger);
    // menumobRef.current.classList.toggle('hidden')
  };

  const history = useHistory();
  const handleLogin = () => {
    history.push("/login");
  };
  const handleLogout = () => {
    dispatch(logout());
    history.push("/login");
  };

  const location = useLocation();
  const url = location.pathname;
  const getCurrentRole = (rolex) => {
    if (rolex == "User") {
      setRole("user");
    } else if (rolex == "Admin") {
      setRole("admin");
    }
  };
  useEffect(() => {
    if (role === "user") {
      const index = NAV.findIndex(
        (item) => item.link === url.includes(item.link)
      );
      if (index !== -1) {
        setActive(index);
      }
    }
  }, [role, url]);
  useEffect(() => {
    if (user) getCurrentRole(user.role);
  }, [user]);

  return (
    <div>
      <nav className={`  ${url.includes("/login") || url.includes("/forgotpassword") || url.includes("/emailverify") ? "hidden" :"block"} flex  fixed top-0 z-50  text-navbar flex-col sm:flex-row  items-center  bg-navbar  justify-between w-full text-lg gap-3 lg:gap-0   px-10`}>
        <div className=" flex flex-row justify-between w-full ">
          <div
            className="flex sm:flex-row flex-col justify-between items-center gap-5 w-full"
            onClick={() => {
              setActive(null);
            }}>
            <Link to={"/"}>
            <img
                src={themes?.logo || Logo}
                className="w-[140px] object-contain h-[70px]"
                alt=""
              />
            </Link>
            <ul
              className={`${hamburger ? "" : "hidden sm:flex"}
						text-base 
						mt-3
					  text-navbar
						lg:mr-6
						flex
						flex-col
						sm:flex-row
						sm:justify-between
						gap-x-6  xl:gap-x-6 2xl:gap-x-8 gap-y-2`}>
              {user && role === "user" 
                ? NAV.map((i, index) => {
                    return (
                      <li
                        className={`${getTW} ${
                          (active === index
                            ? `border-b border-b-notprimary`
                            : "",
                          hamburger
                            ? "  bg-slate-200 border sm:bg-white sm:border-x-0 sm:border-y-0 sm:whitespace-nowrap w-[100%]  px-2 "
                            : "")
                        }`}
                        onClick={() => {
                          setActive(index);
                        }}>
                        <Link className="" to={i.link}>
                          {i.name}
                        </Link>
                      </li>
                    );
                  })
                : ""}
            </ul>
            <div className="relative">
              {user ? (
                <p
                  onClick={() => handleLogout()}
                  class="flex items-center cursor-pointer transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600">
                  <div class="mr-3 text-red-600">
                    <svg
                      class="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                    </svg>
                  </div>
                  Logout
                </p>
              ) : (
                <p
                  onClick={() => handleLogin()}
                  class="flex items-center cursor-pointer transform transition-colors duration-200 border-r-4 border-transparent hover:border-green-500">
                  <div class="mr-3 text-green-500">
                    <svg
                      class="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                    </svg>
                  </div>
                  Login
                </p>
              )}
            </div>
          </div>

          <span
            onClick={handleClick}
            className="cursor-pointer sm:hidden block">
            <svg
              xmlns="<http://www.w3.org/2000/svg>"
              id="menu-button"
              className="h-6 w-6 cursor-pointer sm:hidden block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </span>
        </div>
      </nav>
    </div>
  );
};
const getTW = "hover:border-b hover:border-b-notprimary";

export default Navbar;
