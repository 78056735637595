

// Logout user
const Logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("access_token");
  localStorage.removeItem("persist:root");
};

const authService = {
  Logout,
};

export default authService;
