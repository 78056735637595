import React from "react";


import { Route, Redirect } from "react-router-dom";


const AuthMiddleware = ({ component: Component, isAuthProtected,isPublic, role, key, ...rest }) => {

	
	return (
		<Route
			key={key}
			{...rest}
			render={(props) => {
				if(props.match.path === "/" && props.match.isExact && !localStorage.getItem("access_token")){
					// return <Redirect to={{ pathname: "/home", state: { from: props.location } }} />;
					console.log('111');
					return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
				}
				if(isPublic && localStorage.getItem("access_token")&& role ==="User"){
					console.log('112');
					return <Redirect to={{ pathname: "/trade/savings", state: { from: props.location } }} />;
				}
				if(isPublic && localStorage.getItem("access_token")&& role ==="Admin"){
					console.log('113');
					return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
				}
				if (isAuthProtected && !localStorage.getItem("access_token")) {
					console.log("114");
					return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
				}
				return <div className="min-h-[80vh] bg-white">
					<Component {...props} />
				</div>;
			}}
		/>
	);
};



export default AuthMiddleware;