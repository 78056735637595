import { ReactComponent as LoaderLogo } from "../src/assets/loader.svg";
import { useDispatch, useSelector } from "react-redux";
import Routing from "./routers";
import { listThemes } from "./features/user/userSlice";
import { useEffect } from "react";

function App() {
  const { themes, themesLoading } = useSelector((s) => s.adminUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listThemes());
  }, [dispatch]);

  const textPrimary = themes?.textPrimary || "#314bb1";
  const textNotPrimary = themes?.textNotPrimary || "#FF9721";
  const textNavbar = themes?.textNavbar || "#000000";
  const textPrimaryLight = themes?.textPrimaryLight || "#7785be";

  const bgPrimary = themes?.bgPrimary || "#314bb1";
  const bgSecondary = themes?.bgSecondary || "#FF9721";
  const bgNavbar = themes?.bgNavbar || "#ffff";
  const bgContainer = themes?.bgContainer || "#f7f1f1";
  const bgPrimaryLight = themes?.bgPrimaryLight || "#7785be";

  useEffect(() => {
    document.documentElement.style.setProperty("--text-primary", textPrimary);
    document.documentElement.style.setProperty("--text-notprimary", textNotPrimary);
    document.documentElement.style.setProperty("--text-navbar", textNavbar);
    document.documentElement.style.setProperty("--text-primarylight", textPrimaryLight);
    document.documentElement.style.setProperty("--bg-primary", bgPrimary);
    document.documentElement.style.setProperty("--bg-secondary", bgSecondary);
    document.documentElement.style.setProperty("--bg-navbar", bgNavbar);
    document.documentElement.style.setProperty("--bg-container", bgContainer);
    document.documentElement.style.setProperty("--bg-primarylight", bgPrimaryLight);
  }, [
    textPrimary,
    textNotPrimary,
    textNavbar,
    textPrimaryLight,
    bgPrimary,
    bgSecondary,
    bgNavbar,
    bgContainer,
    bgPrimaryLight,
  ]);

  return (
    <div className="App">
      {themesLoading ? (
        <div className="flex flex-1 h-[100vh] bg-black justify-center items-center">
          <section>
            <LoaderLogo className="rotate linear infinite" />
          </section>
        </div>
      ) : (
        <Routing />
      )}
    </div>
  );
}

export default App;
